import { useState, useEffect } from "react";

import styled from "styled-components";

import { useLocation } from "react-router-dom";

import { Menu, Layout, Switch, message } from "antd";
import { BarsOutlined, ExportOutlined } from "@ant-design/icons";

// import LogoImg from "../../assets/img/logo.png";
import LogoImg from "../../assets/img/logo_company.png";

import useAPI from "../../hooks/useAPI";
import { logout } from "../../service/api/publicAPI";
import { fontSize } from "../../styles/BasicSetting";

const { Sider } = Layout;

const MyNavbarEle = (props) => {
  let location = useLocation();
  //當前收起狀態 true 為展開
  const [collapsedState, setCollapsedState] = useState(true);
  //collapsedWidth的寬度
  const [collapsedWidth, setCollapsedWidth] = useState(0);
  //是否為shrink狀態
  const [isShrink, setIsShrink] = useState(false);

  const call_logout = useAPI(logout);

  useEffect(() => {
    if (call_logout.status === "suc") {
      message.success("登出成功");
    } else if (call_logout.status === "err") {
      message.success("登出成功");
    }
  }, [call_logout.status]);

  useEffect(() => {
    //控制navbar伸縮
    if (props.navbarState === "shrinkClose" && !isShrink) {
      props.setNavbarState("close");
      setCollapsedState(true);
      setCollapsedWidth(0);
    }
    if (props.navbarState === "shrinkClose") {
      return;
    } else if (props.navbarState === "open") {
      setCollapsedState(false);
      setCollapsedWidth(300);
    } else if (props.navbarState === "close" && isShrink) {
      props.setNavbarState("shrinkClose");
      setCollapsedState(true);
      setCollapsedWidth(80);
    } else {
      setCollapsedState(true);
      setCollapsedWidth(0);
    }
  }, [props.navbarState, isShrink]);

  const stop_propagation = (e) => {
    e.nativeEvent.stopImmediatePropagation();
  };

  // console.log("props.CheckAuth", props.CheckAuth);

  const items = [
    {
      label: (
        <Switch
          checked={isShrink}
          onChange={(e) => {
            setIsShrink(e);
          }}
        />
      ),
      key: "dashboard",
      // icon: <IconDigitalKanban style={iconStyle} />,
      // children: null,
      // auth: storeData.board,
      // disabled: false,
      // path: "/dashboard",
    },
    {
      label: <div>&nbsp;V 1.2.2&nbsp;A0</div>,
      key: "logout",
      icon: <ExportOutlined style={{ fontSize: '22px' }}/>,
      onClick: () => call_logout.request(),
      // icon: <IconDigitalKanban style={iconStyle} />,
      // children: null,
      // auth: storeData.board,
      // disabled: false,
      // path: "/dashboard",
    },
  ];

  return (
    <Sider
      className={props.className}
      collapsible
      trigger={null}
      theme="light"
      collapsed={collapsedState}
      collapsedWidth={collapsedWidth} //只在collapsed = true 吃設定
      width={230}
      onClick={stop_propagation}
    >
      <div className="sider-logo-box flex-center">
        <img src={LogoImg} alt="" />
      </div>
      <div className="sider-menu-box">
        <Menu
          mode="inline"
          defaultSelectedKeys={location.pathname}
          onClick={() => props.setNavbarState("close")}
          items={props.s_CheckAuth}
        ></Menu>

        <Menu mode="inline" className="sub-menu" items={items}></Menu>
      </div>
    </Sider>
  );
};

const MyNavbar = styled(MyNavbarEle)`
  /* box-shadow: 5px 0px 3px 2px rgba(124, 124, 124, 0.1); */
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  z-index: 10;
  /*  */
  /* .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: 1px solid ${({ theme }) => theme.componentBg};
  } */
  .ant-menu-item {
    margin-bottom: 0px !important;
  }
  .ant-menu-item-icon,
  .ant-menu-title-content {
    font-size: 19px;
    font-weight: 600;
  }
  .sider-logo-box {
    width: 100%;
    height: 76px;
    box-shadow: 0px 5px 3px 2px rgb(124 124 124 / 10%);
    /* padding: 16px 24px; */
    background-color: ${({ theme }) => theme.componentBg};
  }
  .sider-logo-box img {
    object-position: center center; /* 預設值即為 center center，此行可省 */
    object-fit: scale-down;
    width: 150px;
    height: 76px;
  }
  .sider-menu-box {
    display: flex;
    flex-flow: column nowrap;
    min-height: calc(100vh - 76px);
  }
  .sub-menu {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
  }

  .NavLink,
  .ant-menu-item,
  .ant-menu-title-content,
  .ant-menu-submenu-title {
    font-weight: 400;
    color: ${({ theme }) => theme.NavLink};
  }

  .ant-menu-sub {
    background-color: ${({ theme }) => theme.antmenusub};
  }

  .NavLink-child {
    font-size: ${fontSize.p};
    margin-left: 2rem;
  }

  .ant-menu-item-only-child {
    height: 2rem !important;
  }

  /* .ant-menu-submenu-title {
    font-weight: 300;
    color: ${({ theme }) => theme.NavLink};
  } */
`;
export default MyNavbar;
